<template>
  <div class="admin-guidance-create-download">
    <loading-screen :is-loading="isSaving"></loading-screen>

    <!-- Page Header -->
    <div class="page-header">
      <h1 class="page-title">Create Download</h1>
      <div class="actions">
        <a-button
          class="button-margin-left btn-rounded"
          icon="arrow-left"
          size="large"
          type="primary"
          @click.prevent="goBack"
          >Back</a-button
        >
      </div>
    </div>
    <!-- / Page Header -->

    <!-- Is Loading -->
    <div v-if="isLoading" class="loader">
      <a-spin></a-spin>
    </div>
    <!-- / Is Loading -->

    <div v-if="!isLoading">
      <p style="font-weight: 500">
        Attach your documents directly to teamSOS for staff to download and read
      </p>
    </div>

    <!-- Form -->
    <div v-if="!isLoading" class="form">
      <a-form-item label="Title ">
        <a-input size="large" v-model="downloadLocal.displayName"></a-input>
      </a-form-item>

      <a-form-item label="Visibility">
        <a-radio-group v-model="downloadLocal.scope">
          <a-radio :value="1">Shown to organisation</a-radio>
          <!-- <a-radio :value="2">Shown to whole tenant</a-radio> -->
          <a-radio :value="3">Hidden from lists</a-radio>
        </a-radio-group>
      </a-form-item>

      <a-form-item label="Display Order">
        <a-radio-group v-model="downloadLocal.rank">
          <a-radio v-for="(rank, i) in ranks" :key="i" :value="rank.rank">{{
            rank.label
          }}</a-radio>
        </a-radio-group>
      </a-form-item>
    </div>
    <!-- / Form -->

    <!-- Media -->
    <div v-if="!isLoading" class="form last">
      <a-tabs v-model="uploadType">
        <a-tab-pane key="add-from-computer" tab="Add From Computer">
          <a-form-item label="Upload a document">
            <a-upload
              :multiple="false"
              :file-list="mediaFileList"
              :before-upload="(file) => beforeMediaUpload(file)"
              :remove="(file) => handleMediaRemove(file)"
            >
              <a-button>
                <a-icon type="upload"></a-icon>
                Click to Upload
              </a-button>
            </a-upload>
          </a-form-item>
        </a-tab-pane>
        <a-tab-pane key="copy-from-public-link" tab="Copy From Public Link">
          <a-form-item label="Download From Link">
            <a-input size="large" v-model="mediaUrl"></a-input>
          </a-form-item>
        </a-tab-pane>
        <a-tab-pane key="use-a-public-link" tab="Use a Public Link">
          <a-form-item label="Link Directly To">
            <a-input size="large" v-model="mediaUrl"></a-input>
          </a-form-item>
        </a-tab-pane>
      </a-tabs>
    </div>
    <!-- / Media -->

    <a-button
      @click.prevent="save"
      class="btn-rounded save-button"
      size="large"
      type="primary"
      >Save</a-button
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoadingScreen from "../../../../components/LoadingScreen.vue";
const _ = require("lodash");
import guidanceHelpers from "../../../../helpers/guidance";
import policies from "../../../../api/guidance/policies";

export default {
  components: { LoadingScreen },

  data() {
    return {
      downloadLocal: {
        displayName: "",
        url: "",
        scope: 1,
        rank: 0,
      },

      uploadType: "add-from-computer", // ['add-from-computer', 'copy-from-public-link', 'use-a-public-link']
      mediaFileList: [],
      mediaUrl: "",

      isSaving: false,
    };
  },

  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    }
  },

  methods: {
    goBack() {
      this.$router.push("/admin/guidance/downloads");
    },

    // Media
    handleMediaRemove(file) {
      const index = this.mediaFileList.indexOf(file);
      const newFileList = this.mediaFileList.slice();
      newFileList.splice(index, 1);
      this.mediaFileList = newFileList;
    },

    beforeMediaUpload(file) {
    //   const isMp4 = file.type === "video/mp4" || file.type === "video/x-m4v";
    //   if (!isMp4) {
    //     this.$message.error("Please upload an MP4 file");
    //   }
    //   if (isMp4) {
        this.mediaFileList = [file];
    //   }
      return false;
    },

    getValidationErrors() {
      let errors = [];
      if (this.downloadLocal.displayName.trim().length == 0) {
        errors.push("Please provide a title");
      }
      if (this.uploadType == "add-from-computer") {
        if (this.mediaFileList.length == 0) {
          errors.push("Please upload a document");
        }
      } else {
        if (this.mediaUrl.trim().length == 0) {
          errors.push("Please provide a link");
        }
      }
      return errors;
    },

    async save() {
      let vm = this;

      // Validation
      let validationErrors = this.getValidationErrors();
      if (validationErrors.length) {
        this.$message.error(validationErrors[0]);
        return false;
      }

      vm.isSaving = true;

      let params = {
        ...this.downloadLocal,
        ownerId: this.selectedOrganisation.id,
      };

      policies
        .addPolicy(vm.tenantId, params)
        .then(async (r) => {
          let policyId = r.data["$v"].id;

          // Upload type - add from computer
          if (this.uploadType == "add-from-computer") {
            try {
              await policies.attachPolicyMediaStreamed(
                vm.tenantId,
                policyId,
                vm.mediaFileList[0]
              );
            } catch (e) {
              console.log(e);
              vm.$message.error("Error adding media from computer");
            }
          }

          // Upload type - copy from public link
          if (this.uploadType == "copy-from-public-link") {
            try {
              await policies.attachPolicyMediaReference(
                vm.tenantId,
                policyId,
                vm.mediaUrl
              );
            } catch (e) {
              console.log(e);
              vm.$message.error("Error copying from public link");
            }
          }

          // Upload type - Use a public link
          if (this.uploadType == "use-a-public-link") {
            try {
              await policies.attachPolicyMediaDirect(
                vm.tenantId,
                policyId,
                vm.mediaUrl
              );
            } catch (e) {
              console.log(e);
              vm.$message.error("Error using public link");
            }
          }

          vm.$message.success("Download created successfully");
          vm.$router.push("/admin/guidance/downloads");
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },

  computed: {
    ...mapGetters("missionControl", {
      presences: "presences",
    }),

    ...mapGetters("admin", {
      tenantId: "tenantId",
      selectedOrganisation: "selectedOrganisation",
      isLoading: "isLoading",
    }),

    myPresenceForOrg() {
      return _.find(this.presences, {
        ownerId: this.selectedOrganisation.id,
      });
    },

    ranks() {
      return guidanceHelpers.listRankOptions();
    },
  },
};
</script>

<style scoped lang="scss">
.admin-guidance-create-download {
  padding-bottom: 40px;

  .loader {
    background: #fff;
    padding: 50px;
    text-align: center;
  }

  .form {
    background: #fff;
    padding: 20px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid #fff;

    margin-bottom: 20px;

    &.last {
      margin-bottom: 0;
    }
  }

  .button-margin-left {
    margin-left: 10px;
  }

  .save-button {
    margin-top: 20px;
  }
}
</style>